import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import rssIcon from './rss.svg';

const Header = props => {
  const { siteTitle } = props;

  return (
    <header className="mb-8 pb-4 border-b border-gray-400 sm:flex sm:items-baseline sm:justify-between">
      <h1 className="text-xl font-bold tracking-wider">
        <Link to="/">{siteTitle}</Link>
      </h1>
      <nav className="sm:flex sm:items-center">
        <Link
          to="/"
          activeClassName="navbar-link-active"
          className="navbar-link"
        >
          Home
        </Link>
        <Link
          to="/posts/"
          activeClassName="navbar-link-active"
          className="navbar-link"
        >
          Posts
        </Link>
        <Link
          to="/about/"
          activeClassName="navbar-link-active"
          className="navbar-link"
        >
          About
        </Link>
        <a
          href="/feed.xml"
          className="navbar-link"
        >
          <img
            src={rssIcon}
            alt="RSS"
            title="Subscribe to the RSS feed"
            width="16"
            height="16"
          />
        </a>
      </nav>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired
};

export default Header;
